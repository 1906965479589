import React, { Suspense, lazy } from 'react';
import { withRouter, Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import E404 from './components/errors/e404';
// import Footer from './components/top/footer';
// import Home from './components/top/home';
// import ProjectOverview from './components/top/projects';
// import ProjectDetails from './components/top/projectDetails';

const Home = lazy(() => import('./components/top/home'));
const ProjectDetails = lazy(() => import('./components/top/projectDetails'));
const ProjectOverview = lazy(() => import('./components/top/projects'));
const Footer = lazy(() => import('./components/top/footer'));
const Terms = lazy(() => import('./components/top/terms'));
const Privacy = lazy(() => import('./components/top/privacy'));

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className='loader'>
            <div className='lds-dual-ring'></div>
          </div>
        }>
        <Switch>
          <Route exact path='/page-not-found' render={(props) => <E404 {...props} />} />

          <Route exact path='/gebruiksvoorwaarden' render={(props) => <Terms {...props} />} />
          <Route exact path='/privacyverklaring' render={(props) => <Privacy {...props} />} />

          <Route exact path='/projecten/:project/:lot' render={(props) => <ProjectDetails {...props} />} />
          <Route exact path='/projecten/:project' render={(props) => <ProjectDetails {...props} />} />
          <Route exact path='/projecten' render={(props) => <ProjectOverview {...props} />} />

          <Route exact path='/' render={(props) => <Home {...props} />} />

          <Redirect to='/page-not-found' />
        </Switch>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default withRouter(App);
// export default withRouter(withLocalize(App));
