import React from 'react';

export const Arrow = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.8 17.95'>
      <polygon points='0 0 0 17.95 15.8 8.98 0 0' style={{ fill: '#fff' }} />
    </svg>
  );
};

export const LightArrow = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.17 18.37'>
      <path
        d='M.92,16.8,14.31,9.19.92,1.58Z'
        style={{ fill: 'none', stroke: '#272774', strokeWidth: '1.8370000123977661px' }}
      />
    </svg>
  );
};

export const Home = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.69 18.38'>
      <path
        d='M.92,7.18V17.46H15.77V7.17l-7.34-6Z'
        style={{ fill: 'none', stroke: '#272774', strokeWidth: '1.8370000123977661px' }}
      />
    </svg>
  );
};

export const Facebook = ({ className }) => {
  return (
    <a className={className} href='https://' target='_blank' rel='noopener noreferrer'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.32 19.75'>
        <path
          d='M6.15,4.35A.84.84,0,0,1,7,3.4H9.27V0H6.18A3.87,3.87,0,0,0,2,4.14V6.4H0V9.88H2v9.87H6.15V9.88H9L9.32,6.4H6.15Z'
          style={{ fill: '#394697' }}
        />
      </svg>
    </a>
  );
};

export const Instagram = ({ className }) => {
  return (
    <a className={className} href='http://' target='_blank' rel='noopener noreferrer'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'>
        <defs>
          <clipPath id='a' transform='translate(0)'>
            <rect width='19.9' height='19.9' style={{ fill: 'none' }} />
          </clipPath>
        </defs>
        <g style={{ clipPath: 'url(#a)' }}>
          <path
            d='M17.85,14.12a3.4,3.4,0,0,1-1.64,3.2,4.37,4.37,0,0,1-2.09.53H5.76A3.51,3.51,0,0,1,2,14.12V5.78A3.56,3.56,0,0,1,5.77,2h8.35a3.4,3.4,0,0,1,3.2,1.65,4.43,4.43,0,0,1,.53,2.1ZM14.12,0H5.75A5.53,5.53,0,0,0,0,5.78v8.36A5.48,5.48,0,0,0,5.76,19.9h8.37a5.54,5.54,0,0,0,5.77-5.78V5.75A5.53,5.53,0,0,0,14.12,0'
            transform='translate(0)'
            style={{ fill: '#394697' }}
          />
          <path
            d='M10,13.76A3.81,3.81,0,1,1,13.76,10,3.82,3.82,0,0,1,10,13.76m0-9A5.24,5.24,0,1,0,15.19,10,5.23,5.23,0,0,0,10,4.71'
            transform='translate(0)'
            style={{ fill: '#394697' }}
          />
          <path
            d='M16.27,4.74a1.12,1.12,0,1,1-2.24,0,1.12,1.12,0,1,1,2.24,0'
            transform='translate(0)'
            style={{ fill: '#394697' }}
          />
        </g>
      </svg>
    </a>
  );
};

export const Linkedin = ({ className }) => {
  return (
    <a
      className={className}
      href='https://www.linkedin.com/company/p.k.m.c./about/'
      target='_blank'
      rel='noopener noreferrer'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.75 18.88'>
        <path d='M2.34,4.4h0a2.21,2.21,0,1,0,0-4.4,2.21,2.21,0,1,0-.05,4.4Z' style={{ fill: '#394697' }} />
        <path
          d='M14.88,5.84A4.21,4.21,0,0,0,11.06,8V8h0l0,0V6.14H6.83c0,1.2,0,12.74,0,12.74h4.23V11.77a2.84,2.84,0,0,1,.14-1,2.3,2.3,0,0,1,2.17-1.54c1.53,0,2.15,1.17,2.15,2.88v6.81h4.23v-7.3C19.75,7.66,17.66,5.84,14.88,5.84Z'
          style={{ fill: '#394697' }}
        />
        <rect x='0.25' y='6.14' width='4.23' height='12.74' style={{ fill: '#394697' }} />
      </svg>
    </a>
  );
};

export const EuroWhite = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.4 53.4'>
      <path
        d='M21.56,15.68a11.34,11.34,0,0,1,7.21-2.41,12.59,12.59,0,0,1,7.31,2.06,11.63,11.63,0,0,1,4.32,5.53h4.78a16.08,16.08,0,0,0-5.77-8.61A17.08,17.08,0,0,0,28.82,9.1a17.17,17.17,0,0,0-7.53,1.64,15.09,15.09,0,0,0-5.65,4.64,17.65,17.65,0,0,0-3.08,7H7.74v2.34h4.47c-.06,1-.1,1.75-.1,2.28a24.44,24.44,0,0,0,.15,2.73H7.74V32h5a16,16,0,0,0,5.67,9.26,16.21,16.21,0,0,0,10.44,3.45,17.08,17.08,0,0,0,10.59-3.15A16.11,16.11,0,0,0,45.18,33H40.4a11.6,11.6,0,0,1-4.32,5.54,12.59,12.59,0,0,1-7.31,2.06,11.38,11.38,0,0,1-7-2.26A12.09,12.09,0,0,1,17.48,32h12V29.7H17a15.39,15.39,0,0,1-.2-2.73,16.58,16.58,0,0,1,.15-2.28H29.52V22.35H17.38A11.89,11.89,0,0,1,21.56,15.68Z'
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};

export const Euro = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.4 53.4'>
      <path
        d='M21.56,15.68a11.34,11.34,0,0,1,7.21-2.41,12.59,12.59,0,0,1,7.31,2.06,11.63,11.63,0,0,1,4.32,5.53h4.78a16.08,16.08,0,0,0-5.77-8.61A17.08,17.08,0,0,0,28.82,9.1a17.17,17.17,0,0,0-7.53,1.64,15.09,15.09,0,0,0-5.65,4.64,17.65,17.65,0,0,0-3.08,7H7.74v2.34h4.47c-.06,1-.1,1.75-.1,2.28a24.44,24.44,0,0,0,.15,2.73H7.74V32h5a16,16,0,0,0,5.67,9.26,16.21,16.21,0,0,0,10.44,3.45,17.08,17.08,0,0,0,10.59-3.15A16.11,16.11,0,0,0,45.18,33H40.4a11.6,11.6,0,0,1-4.32,5.54,12.59,12.59,0,0,1-7.31,2.06,11.38,11.38,0,0,1-7-2.26A12.09,12.09,0,0,1,17.48,32h12V29.7H17a15.39,15.39,0,0,1-.2-2.73,16.58,16.58,0,0,1,.15-2.28H29.52V22.35H17.38A11.89,11.89,0,0,1,21.56,15.68Z'
        style={{ fill: '#394697' }}
      />
    </svg>
  );
};

export const Leaf = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.4 53.4'>
      <path
        d='M42.89,19.48c-.06-3.64-.28-6.79-.43-8.54l-.19-2.16-2.08.59c-4.67,1.34-20.25,6.18-24.63,12.2a12.87,12.87,0,0,0-2.5,10.66A13.69,13.69,0,0,0,16,38a33,33,0,0,1-6.63,2.29h0l.89,3.46a34.31,34.31,0,0,0,8.5-3.17l.25.19a19.63,19.63,0,0,0,11,4.05,11.11,11.11,0,0,0,9.32-4.91c2.6-3.57,3.37-10.72,3.48-17.19ZM36.52,37.77C32,43.94,25,40.41,22,38.42A26.19,26.19,0,0,0,29,29.63L25.86,28a22.31,22.31,0,0,1-6.71,8.07,10.22,10.22,0,0,1-2.61-4.66,9.42,9.42,0,0,1,1.91-7.75C21,20.16,30.31,16.13,39.07,13.43,39.72,23.27,39.19,34.1,36.52,37.77Z'
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};

export const City = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.4 53.4'>
      <path
        d='M.48,44.78H4.14V23.88H18.47v12.3H31.21v8.6h3.66V32.48H29.21V8.62H14.49V20.19H.48ZM18.15,12.32h7.4V32.48H22.13V20.19h-4Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='38.69 44.78 42.35 44.78 42.35 12.32 49.74 12.32 49.74 44.78 53.4 44.78 53.4 8.62 38.69 8.62 38.69 44.78'
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};

export const Square = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.62 15.62'>
      <rect x='1.84' y='1.84' width='11.94' height='11.94' style={{ fill: 'none' }} />
      <path d='M0,15.62H15.62V0H0ZM1.84,1.84H13.78V13.78H1.84Z' style={{ fill: '#272774' }} />
    </svg>
  );
};

export const Location = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.37 25.48'>
      <path
        d='M9.19,1.84A7.36,7.36,0,0,0,1.84,9.19c0,2.59,3.58,8.38,7.35,13.3,3.76-4.92,7.35-10.71,7.35-13.3A7.36,7.36,0,0,0,9.19,1.84Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M9.19,0A9.2,9.2,0,0,0,0,9.19c0,4.4,7.05,13.58,8.46,15.37l.73.92.72-.92c1.41-1.79,8.46-11,8.46-15.37A9.19,9.19,0,0,0,9.19,0Zm0,22.49C5.42,17.57,1.84,11.78,1.84,9.19a7.35,7.35,0,0,1,14.7,0C16.54,11.78,13,17.57,9.19,22.49Z'
        style={{ fill: '#272774' }}
      />
    </svg>
  );
};

export const Bed = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.62 16.04'>
      <path
        d='M19.5,7.35H4.16A2.17,2.17,0,0,0,2,9.12v2.64H21.65V9.55A2.18,2.18,0,0,0,19.5,7.35Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M24.62,16V3.43h-2V6.78A4.13,4.13,0,0,0,19.5,5.35H4.16A4.11,4.11,0,0,0,2,5.93V0H0V9a3.81,3.81,0,0,0,0,.59L0,16H2V13.76H22.62V16Zm-3-4.28H2V9.12A2.17,2.17,0,0,1,4.16,7.35H19.5a2.18,2.18,0,0,1,2.15,2.2Z'
        style={{ fill: '#394697' }}
      />
    </svg>
  );
};

export const Bath = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 14.96'>
      <path d='M2,9.44A3.53,3.53,0,0,0,5.52,13h14A3.53,3.53,0,0,0,23,9.44V5.91H2Z' style={{ fill: 'none' }} />
      <path
        d='M18.71,0V2h2.58V3.91H0V9.44A5.53,5.53,0,0,0,5.52,15h14A5.53,5.53,0,0,0,25,9.44V3.91H23.29V0ZM23,9.44A3.53,3.53,0,0,1,19.48,13h-14A3.53,3.53,0,0,1,2,9.44V5.91H23Z'
        style={{ fill: '#394697' }}
      />
    </svg>
  );
};

export const House = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.25 18.99'>
      <polygon
        points='15.25 9.99 15.25 7.91 8.71 2.57 2 7.93 2 16.99 15.25 16.99 15.25 9.99'
        style={{ fill: 'none' }}
      />
      <rect x='17.25' y='11.99' width='5' height='5' style={{ fill: 'none' }} />
      <path
        d='M17.25,7,8.72,0,0,7V19H24.25V10h-7ZM2,7.93,8.71,2.57l6.54,5.34V17H2ZM22.25,12v5h-5V12Z'
        style={{ fill: '#394697' }}
      />
    </svg>
  );
};

export const Note = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.56 20'>
      <polygon
        points='13.08 5.85 11.08 5.85 11.08 4.44 6.43 4.44 6.43 5.85 4.43 5.85 4.43 4.44 2 4.44 2 18 15.56 18 15.56 4.44 13.08 4.44 13.08 5.85'
        style={{ fill: 'none' }}
      />
      <path
        d='M13.08,2.44V0h-2V2.44H6.44V0h-2V2.44H0V20H17.56V2.44ZM15.56,18H2V4.44H4.44V5.85h2V4.44h4.64V5.85h2V4.44h2.48Z'
        style={{ fill: '#394697' }}
      />
      <line x1='4.31' y1='10.87' x2='10.16' y2='10.87' style={{ fill: 'none', stroke: '#394697' }} />
      <line x1='7.24' y1='13.28' x2='13.09' y2='13.28' style={{ fill: 'none', stroke: '#394697' }} />
    </svg>
  );
};

export const Land = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.38 15.21'>
      <rect x='10.19' y='2' width='5' height='5' style={{ fill: 'none' }} />
      <polygon
        points='17.36 6 17.36 9.46 8.03 9.46 8.03 6 5.63 6 2.9 13.21 22.48 13.21 19.75 6 17.36 6'
        style={{ fill: 'none' }}
      />
      <path
        d='M17.19,4V0h-9V4H4.25L0,15.21H25.38L21.13,4Zm-7-2h5V7h-5ZM2.9,13.21,5.63,6H8V9.46h9.33V6h2.39l2.74,7.21Z'
        style={{ fill: '#394697' }}
      />
    </svg>
  );
};

export const Download = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.07 19.68'>
      <polygon points='8.13 2 13.53 11.51 18.94 2 8.13 2' style={{ fill: '#fff' }} />
      <path d='M4.69,0l8.84,15.56L22.38,0ZM8.13,2H18.94l-5.41,9.51Z' style={{ fill: '#394697' }} />
      <polygon
        points='25.07 17.68 2 17.68 2 15.03 0 15.03 0 19.68 27.07 19.68 27.07 15.03 25.07 15.03 25.07 17.68'
        style={{ fill: '#394697' }}
      />
    </svg>
  );
};

export const Print = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.68 23'>
      <rect x='9.04' y='2' width='8.6' height='3.13' style={{ fill: 'none' }} />
      <polygon
        points='19.64 13 19.64 17.4 24.68 17.4 24.68 7.36 2 7.36 2 17.4 7.04 17.4 7.04 13 19.64 13'
        style={{ fill: 'none' }}
      />
      <path
        d='M26.68,19.4v-14h-7V0H7V5.36H0v14H7V23h12.6V19.4ZM9,2h8.6V5.13H9ZM2,17.4v-10H24.68v10h-5V13H7v4.4ZM9,21V15h8.6v6Z'
        style={{ fill: '#394697' }}
      />
      <rect x='9.04' y='15' width='8.6' height='6' style={{ fill: '#fff' }} />
    </svg>
  );
};

export const Floor = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.96 18.96'>
      <polygon
        points='13.31 7.65 7.65 7.65 7.65 13.31 2 13.31 2 16.96 16.96 16.96 16.96 2 13.31 2 13.31 7.65'
        style={{ fill: 'none' }}
      />
      <path d='M11.31,0V5.65H5.65v5.66H0V19H19V0ZM17,17H2V13.31H7.65V7.65h5.66V2H17Z' style={{ fill: '#394697' }} />
    </svg>
  );
};

export const Checkmark = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'>
      <path d='M0 7.2l1.4-1.4L5 9.3 12.6 2 14 3.3 5 12z' style={{ fill: '#fff' }} />
    </svg>
  );
};
