import React, { Component } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { getLocalizedUrl } from '../../services/languageService';

import styles from '../../styles/modules/navigation.module.scss';
import { Logo } from '../images/svg';
import NavLinks from './navLinks';
import { Home, LightArrow, Facebook, Linkedin, Instagram } from '../images/icons';

class Navigation extends Component {
  state = {
    navOpen: false,
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClick);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const prevProps = this.props;
    const prevState = this.state;

    if (prevProps.className !== nextProps.className || prevState.navOpen !== nextState.navOpen) return true;
    return false;
  }

  handleScroll = () => {
    if (this.state.navOpen) this.setState({ navOpen: false });
  };

  handleResize = () => {
    if (this.state.navOpen) this.setState({ navOpen: false });
  };

  handleClick = (e) => {
    const { className } = e.target;
    if (className && typeof className === 'string')
      if (this.state.navOpen && !className.includes('navigation')) this.setState({ navOpen: false });
  };

  render() {
    const { className } = this.props;
    const { navOpen } = this.state;

    return (
      <nav className={className}>
        <div className={`${styles.container} ${navOpen ? styles.container__navOpened : ''} container__fixed-width`}>
          <div
            className={`${styles.button} ${navOpen ? styles.button__active : ''}`}
            onClick={() => this.setState({ navOpen: !navOpen })}>
            <div className={styles.icon}></div>
            <div className={styles.icon}></div>
            <div className={styles.icon}></div>
          </div>
          <NavHashLink smooth to={getLocalizedUrl('#home')} className={styles.logo}>
            <Logo />
          </NavHashLink>
          <div className={styles.social}>
            {/* <Facebook className={styles.icon} /> */}
            <Linkedin className={styles.icon} />
            {/* <Instagram className={styles.icon} /> */}
          </div>
          <div className={styles.linkContainer}>
            <NavLinks styles={styles} />
            <div className={styles.contactContainer}>
              <div className={styles.contactContainer__item}>
                <Home />
                <a className='contact-text' href='mailto:info@pkmc.be'>
                  info@pkmc.be
                </a>
              </div>
              <div className={styles.contactContainer__item}>
                <LightArrow />
                <a className='contact-text' href='tel:+32475397591'>
                  +32 475 39 75 91
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
