import React from 'react';
import Header from '../extra/header';
import E404Content from '../extra/e404Content';
import { Helmet } from 'react-helmet';

const E404 = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>E404 &#124; PKMC</title>
      </Helmet>

      <Header />
      <E404Content />
    </React.Fragment>
  );
};

export default E404;
