import React from 'react';
import Scrollspy from 'react-scrollspy';
import { NavHashLink } from 'react-router-hash-link';
import { getLocalizedUrl } from '../../services/languageService';

const NavLinks = React.memo(({ styles, closeNav }) => {
  return (
    <Scrollspy
      items={['home', 'projects', 'about_us', 'contact']}
      currentClassName={styles.active}
      className={styles.links}
      offset={-300}>
      <NavHashLink smooth className={`${styles.link} navlink`} to={getLocalizedUrl('#home')} onClick={closeNav}>
        Home
        <div className={`${styles.marker} ${styles.marker__top}`}></div>
      </NavHashLink>
      <NavHashLink smooth className={`${styles.link} navlink`} to={getLocalizedUrl('#projects')} onClick={closeNav}>
        Projecten
        <div className={`${styles.marker} ${styles.marker__bottom}`}></div>
      </NavHashLink>
      <NavHashLink smooth className={`${styles.link} navlink`} to={getLocalizedUrl('#about_us')} onClick={closeNav}>
        Over Ons
        <div className={`${styles.marker} ${styles.marker__bottom}`}></div>
      </NavHashLink>
      <NavHashLink smooth className={`${styles.link} navlink`} to={getLocalizedUrl('#contact')} onClick={closeNav}>
        Contact
      </NavHashLink>
    </Scrollspy>
  );
});

export default NavLinks;
