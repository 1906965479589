import React from 'react';
import { Link } from 'react-router-dom';
import { Arrow } from './../../images/icons';
import { getLocalizedUrl } from './../../../services/languageService';

const NameBox = ({ styles, project, lot, showLink, startInterval, clearInterval }) => {
  return (
    <div className={styles.namebox}>
      <div className={styles.container} onMouseEnter={clearInterval} onMouseLeave={startInterval}>
        {lot ? lot.title : project.title}
        {lot ? (
          <Link to={getLocalizedUrl(`projecten/${project.alias}`)}>
            <div className={styles.container__link}>
              <p className='slide-subcontent'>
                Bekijk project <Arrow />
              </p>
            </div>
          </Link>
        ) : showLink ? (
          <Link to={getLocalizedUrl(`projecten/${project.alias}`)}>
            <div className={styles.container__link}>
              <p className='slide-subcontent'>
                Bekijk meer <Arrow />
              </p>
            </div>
          </Link>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default NameBox;
