import React from 'react';

export const Logo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 428.2 130.73'>
      <path
        d='M428.2,123.77V111.64c-1-.29-1.48-.06-1.43,1s-.07,1.8,0,2.69a2.21,2.21,0,0,1-1.32,2.37c-11.26,7-23.37,10.82-36.78,10.17-9.82-.46-18.32-3.73-25.08-11-6.08-6.56-9.34-14.57-11.36-23.12a119.39,119.39,0,0,1-2.48-36.6,86.68,86.68,0,0,1,6.39-27.42c3.15-7.45,7.39-14.17,13.75-19.34a33.69,33.69,0,0,1,21.21-7.75c8.63-.17,16.55,2.09,24.42,5.64l-1.27.48a10.24,10.24,0,0,0-6.79,11.61,10.37,10.37,0,0,0,10,8.37,10.18,10.18,0,0,0,10.12-8.12,10.05,10.05,0,0,0-6-11.59c-5.14-2.1-10.26-4.26-15.36-6.38h21.57V0H0V3H7.24V127.85H0v2.83H425.12a9.59,9.59,0,0,1,1.2,0c1.22.18,1.64-.36,1.48-1.52-.06-.43-.08-.86-.13-1.4h-20.3c3.42-1.36,6.71-2.52,9.86-4s6.26-3.16,9.55-4.85c0,1.4,0,2.6,0,3.8S427.2,124.14,428.2,123.77ZM292.54,2.8q-17.18,46.92-34.33,93.84l-.35,0Q242.74,49.8,227.57,2.8ZM116.8,3h61.93L116.8,76Zm-89,.1c.48,0,.92-.08,1.35-.09,5.9,0,11.8,0,17.69,0a4.27,4.27,0,0,1,2.32.75c7.06,5.18,11,12.39,13.05,20.7,2.24,9.06,1.95,18-2,26.64A24.07,24.07,0,0,1,48.66,63.14a3.68,3.68,0,0,1-1.55.45c-6.25,0-12.5,0-18.75,0a3.59,3.59,0,0,1-.56-.12ZM96.26,127.71H27.82V65.28h1.83c6.85,0,13.71.08,20.56,0,7.11-.1,13.72-2,19.4-6.42C76.31,53.56,80,46.5,81.14,38.18a33.15,33.15,0,0,0-5.35-24A30.35,30.35,0,0,0,61.26,3h35Zm20.48.05v-1.63c0-15.19,0-30.39,0-45.59a4.46,4.46,0,0,1,1-2.5c1.24-1.61,2.65-3.09,4.1-4.74l40.67,54.46Zm87.44,0H203c-5.55,0-11.1,0-16.65,0a2.19,2.19,0,0,1-2-1q-24.63-33.53-49.31-67c-.23-.32-.44-.65-.7-1L144.7,46.52Q162.31,25.67,179.88,4.8a5,5,0,0,1,4.39-2.1c6,.14,12.1,0,18.16,0h1.75Zm89.46-.11h-87.5V8.44l.24-.09C220.52,47.82,234.67,87.29,249,127.16L293.47,5.52l.17,0Zm20.54.07V2.77h63.44c-16.36,4.38-29.87,12.87-39.41,27.14-7.15,10.7-11.34,22.5-11,35.34.83,28.36,19.3,54.23,47.64,62.51Z'
        style={{ fill: '#272774' }}
      />
    </svg>
  );
};

export const Logo2 = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152.6 59.01'>
      <path
        d='M6.57,53.62a3,3,0,0,0,1-2.41,3,3,0,0,0-1-2.4A4.09,4.09,0,0,0,3.79,48H.53v6.5H3.79A4.14,4.14,0,0,0,6.57,53.62Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M48,50.63a2.39,2.39,0,0,0-2.46,0,2.34,2.34,0,0,0-.87,1,3.53,3.53,0,0,0,0,3,2.34,2.34,0,0,0,.87,1,2.39,2.39,0,0,0,2.46,0,2.48,2.48,0,0,0,.87-1,3.53,3.53,0,0,0,0-3A2.48,2.48,0,0,0,48,50.63Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M104.07,50.4h-1.33V53h1.33a1.6,1.6,0,0,0,1.12-.34,1.3,1.3,0,0,0,.37-1,1.32,1.32,0,0,0-.37-1A1.6,1.6,0,0,0,104.07,50.4Z'
        style={{ fill: 'none' }}
      />
      <polygon
        points='50.9 6.1 88.31 6.1 110.71 36.94 119.57 36.94 92.74 0 83.88 0 83.88 0 55.33 0 50.9 6.1'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='19.2 36.94 56.6 36.94 70.88 17.29 85.15 36.94 94.02 36.94 75.31 11.19 75.32 11.18 44.3 11.18 44.3 20.17 40.54 20.17 40.54 20.17 40.54 20.17 40.54 5.85 40.54 4.18 33.7 4.18 33.7 5.85 34.98 5.85 34.98 15.2 19.2 36.94'
        style={{ fill: '#fff' }}
      />
      <path
        d='M3.76,55a4.66,4.66,0,0,0,3.16-1,3.94,3.94,0,0,0,0-5.52,4.66,4.66,0,0,0-3.16-1H0v11.3H.53V55ZM.53,48H3.79a4.09,4.09,0,0,1,2.78.85,3,3,0,0,1,1,2.4,3,3,0,0,1-1,2.41,4.14,4.14,0,0,1-2.78.84H.53Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='13.94 47.46 13.94 58.76 14.47 58.76 14.47 55.77 17.05 53 21.89 58.76 22.54 58.76 17.41 52.63 22.2 47.46 21.55 47.46 14.47 55.06 14.47 47.46 13.94 47.46'
        style={{ fill: '#fff' }}
      />
      <path
        d='M32.49,50.29a2.93,2.93,0,0,1,2.37,1.32l2.3-2.15a5.22,5.22,0,0,0-2-1.68,6.62,6.62,0,0,0-2.81-.58,6.34,6.34,0,0,0-3.09.75,5.45,5.45,0,0,0-2.16,2.1,6.39,6.39,0,0,0,0,6.12,5.43,5.43,0,0,0,2.16,2.09,6.34,6.34,0,0,0,3.09.75,6.48,6.48,0,0,0,2.81-.58,5.11,5.11,0,0,0,2-1.67l-2.3-2.15a2.93,2.93,0,0,1-2.37,1.32,2.34,2.34,0,0,1-1.83-.76,3.38,3.38,0,0,1,0-4.12A2.34,2.34,0,0,1,32.49,50.29Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M49.89,48a6.87,6.87,0,0,0-6.28,0,5.6,5.6,0,0,0-2.18,2.11,6.24,6.24,0,0,0,0,6.08,5.6,5.6,0,0,0,2.18,2.11,6.95,6.95,0,0,0,6.28,0,5.67,5.67,0,0,0,2.18-2.11,6.24,6.24,0,0,0,0-6.08A5.67,5.67,0,0,0,49.89,48Zm-1,6.64a2.48,2.48,0,0,1-.87,1,2.39,2.39,0,0,1-2.46,0,2.34,2.34,0,0,1-.87-1,3.53,3.53,0,0,1,0-3,2.34,2.34,0,0,1,.87-1,2.39,2.39,0,0,1,2.46,0,2.48,2.48,0,0,1,.87,1,3.53,3.53,0,0,1,0,3Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='60.28 47.46 57.28 47.46 57.28 58.76 60.83 58.76 60.83 53.63 64.94 58.76 67.94 58.76 67.94 47.46 64.39 47.46 64.39 52.59 60.28 47.46'
        style={{ fill: '#fff' }}
      />
      <path
        d='M78,56a2.84,2.84,0,0,1-1.09.15A6.48,6.48,0,0,1,75,55.83a7.53,7.53,0,0,1-1.73-.75l-1.17,2.77a6.64,6.64,0,0,0,2.09.85,10.92,10.92,0,0,0,2.62.31,6.68,6.68,0,0,0,2.76-.5,3.91,3.91,0,0,0,1.71-1.37,3.44,3.44,0,0,0,.57-1.93,2.86,2.86,0,0,0-.62-1.94,3.66,3.66,0,0,0-1.48-1,13.43,13.43,0,0,0-2.09-.56,9,9,0,0,1-1.37-.36q-.42-.18-.42-.51c0-.48.47-.71,1.41-.71a6.49,6.49,0,0,1,3,.84l1.1-2.76a6.91,6.91,0,0,0-1.9-.74,9.91,9.91,0,0,0-2.18-.25,6.91,6.91,0,0,0-2.76.5,3.79,3.79,0,0,0-1.71,1.38A3.44,3.44,0,0,0,72.29,51a2.85,2.85,0,0,0,.62,2,3.58,3.58,0,0,0,1.45,1,14.88,14.88,0,0,0,2.09.59,10.38,10.38,0,0,1,1.4.38.58.58,0,0,1,.42.54A.5.5,0,0,1,78,56Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='91.89 58.76 91.89 50.41 95.06 50.41 95.06 47.46 85.07 47.46 85.07 50.41 88.24 50.41 88.24 58.76 91.89 58.76'
        style={{ fill: '#fff' }}
      />
      <path
        d='M108.71,53.84a4.5,4.5,0,0,0-.08-4.38A3.88,3.88,0,0,0,106.92,48a6.13,6.13,0,0,0-2.62-.52H99.09v11.3h3.65V55.92h1l1.84,2.84h3.9l-2.27-3.49A3.73,3.73,0,0,0,108.71,53.84Zm-3.52-1.14a1.6,1.6,0,0,1-1.12.34h-1.33V50.4h1.33a1.6,1.6,0,0,1,1.12.34,1.32,1.32,0,0,1,.37,1A1.3,1.3,0,0,1,105.19,52.7Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M122.76,57.61a5.34,5.34,0,0,0,1.39-3.94V47.46h-3.58v6.1a3,3,0,0,1-.43,1.81,1.43,1.43,0,0,1-1.23.56,1.45,1.45,0,0,1-1.23-.56,3.08,3.08,0,0,1-.42-1.81v-6.1h-3.65v6.21A5.34,5.34,0,0,0,115,57.61a5.2,5.2,0,0,0,3.88,1.4h0A5.18,5.18,0,0,0,122.76,57.61Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M134.7,50.29a2.91,2.91,0,0,1,2.36,1.32l2.31-2.15a5.37,5.37,0,0,0-2-1.68,6.7,6.7,0,0,0-2.82-.58,6.36,6.36,0,0,0-3.09.75,5.45,5.45,0,0,0-2.16,2.1,6.39,6.39,0,0,0,0,6.12,5.43,5.43,0,0,0,2.16,2.09,6.36,6.36,0,0,0,3.09.75,6.55,6.55,0,0,0,2.82-.58,5.25,5.25,0,0,0,2-1.67l-2.31-2.15a2.91,2.91,0,0,1-2.36,1.32,2.36,2.36,0,0,1-1.84-.76,3.38,3.38,0,0,1,0-4.12A2.36,2.36,0,0,1,134.7,50.29Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='142.61 47.46 142.61 50.41 145.78 50.41 145.78 58.76 149.43 58.76 149.43 50.41 152.6 50.41 152.6 47.46 142.61 47.46'
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};

export const PkContructColor = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.32 43.83'>
      <path
        d='M77.29,37.43h-1v2h1a1.12,1.12,0,0,0,.82-.26.91.91,0,0,0,.28-.72,1,1,0,0,0-.28-.73A1.17,1.17,0,0,0,77.29,37.43Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M35.63,37.6a1.69,1.69,0,0,0-.91-.26,1.73,1.73,0,0,0-.92.26,1.83,1.83,0,0,0-.64.73,2.59,2.59,0,0,0,0,2.22,1.83,1.83,0,0,0,.64.73,1.73,1.73,0,0,0,.92.26,1.69,1.69,0,0,0,.91-.26,1.85,1.85,0,0,0,.65-.73,2.69,2.69,0,0,0,0-2.22A1.85,1.85,0,0,0,35.63,37.6Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M4.88,39.82a2.57,2.57,0,0,0,0-3.57,3,3,0,0,0-2.07-.63H.39v4.83H2.81A3,3,0,0,0,4.88,39.82Z'
        style={{ fill: 'none' }}
      />
      <polygon
        points='65.58 4.53 65.58 4.53 82.21 27.43 88.8 27.43 68.87 0 62.29 0 62.29 0 68.87 0 65.58 4.53'
        style={{ fill: '#06589c' }}
      />
      <polygon points='63.23 27.43 69.82 27.43 55.93 8.31 52.64 12.84 63.23 27.43' style={{ fill: '#06589c' }} />
      <polygon points='37.8 4.53 65.58 4.53 65.58 4.53 68.87 0 62.29 0 41.09 0 37.8 4.53' style={{ fill: '#1887c9' }} />
      <polygon
        points='14.26 27.43 42.03 27.43 52.64 12.84 55.93 8.31 55.93 8.3 32.9 8.3 32.9 14.98 30.11 14.98 30.11 14.98 30.11 14.98 30.11 4.35 30.11 3.1 25.02 3.1 25.02 4.35 25.98 4.35 25.98 11.29 14.26 27.43'
        style={{ fill: '#1887c9' }}
      />
      <path
        d='M5.14,40.08a2.92,2.92,0,0,0,0-4.1,3.48,3.48,0,0,0-2.35-.73H0v8.38H.39V40.82h2.4A3.48,3.48,0,0,0,5.14,40.08ZM.39,35.62H2.81a3,3,0,0,1,2.07.63,2.57,2.57,0,0,1,0,3.57,3,3,0,0,1-2.07.63H.39Z'
        style={{ fill: '#434342' }}
      />
      <polygon
        points='10.36 35.25 10.36 43.63 10.75 43.63 10.75 41.42 12.66 39.36 16.26 43.63 16.74 43.63 12.93 39.08 16.49 35.25 16 35.25 10.75 40.89 10.75 35.25 10.36 35.25'
        style={{ fill: '#434342' }}
      />
      <path
        d='M24.13,37.34a2.18,2.18,0,0,1,1.76,1l1.71-1.6a3.83,3.83,0,0,0-1.52-1.24A4.79,4.79,0,0,0,24,35.05a4.73,4.73,0,0,0-2.29.56,4,4,0,0,0-1.6,1.56,4.73,4.73,0,0,0,0,4.54,4,4,0,0,0,1.6,1.56,4.73,4.73,0,0,0,2.29.56,4.79,4.79,0,0,0,2.09-.44,3.83,3.83,0,0,0,1.52-1.24l-1.71-1.6a2.18,2.18,0,0,1-1.76,1A1.74,1.74,0,0,1,22.77,41a2.51,2.51,0,0,1,0-3.06A1.74,1.74,0,0,1,24.13,37.34Z'
        style={{ fill: '#434342' }}
      />
      <path
        d='M37.05,35.62a5,5,0,0,0-4.66,0,4.05,4.05,0,0,0-1.62,1.56,4.62,4.62,0,0,0,0,4.52,4.05,4.05,0,0,0,1.62,1.56,5,5,0,0,0,4.66,0,4.05,4.05,0,0,0,1.62-1.56,4.69,4.69,0,0,0,0-4.52A4.05,4.05,0,0,0,37.05,35.62Zm-.77,4.93a1.85,1.85,0,0,1-.65.73,1.69,1.69,0,0,1-.91.26,1.73,1.73,0,0,1-.92-.26,1.83,1.83,0,0,1-.64-.73,2.59,2.59,0,0,1,0-2.22,1.83,1.83,0,0,1,.64-.73,1.73,1.73,0,0,1,.92-.26,1.69,1.69,0,0,1,.91.26,1.85,1.85,0,0,1,.65.73,2.69,2.69,0,0,1,0,2.22Z'
        style={{ fill: '#434342' }}
      />
      <polygon
        points='44.76 35.25 42.53 35.25 42.53 43.63 45.17 43.63 45.17 39.82 48.23 43.63 50.46 43.63 50.46 35.25 47.82 35.25 47.82 39.06 44.76 35.25'
        style={{ fill: '#434342' }}
      />
      <path
        d='M59.11,43.45a2.91,2.91,0,0,0,1.27-1A2.57,2.57,0,0,0,60.8,41a2.09,2.09,0,0,0-.46-1.44,2.67,2.67,0,0,0-1.1-.76,8.8,8.8,0,0,0-1.55-.41,7.2,7.2,0,0,1-1-.27c-.21-.09-.31-.21-.31-.38,0-.35.35-.53,1-.53a4.87,4.87,0,0,1,2.24.62l.82-2a5.24,5.24,0,0,0-1.41-.54,7.34,7.34,0,0,0-1.63-.19,5,5,0,0,0-2,.38,2.72,2.72,0,0,0-1.27,1,2.54,2.54,0,0,0-.43,1.44,2.19,2.19,0,0,0,.46,1.45,2.67,2.67,0,0,0,1.08.76,11.22,11.22,0,0,0,1.55.44,6,6,0,0,1,1,.29c.21.09.32.22.32.39a.37.37,0,0,1-.24.34,2,2,0,0,1-.81.12,4.79,4.79,0,0,1-1.36-.21,5.41,5.41,0,0,1-1.29-.56L53.56,43a4.73,4.73,0,0,0,1.55.63,7.87,7.87,0,0,0,1.94.24h0A5,5,0,0,0,59.11,43.45Z'
        style={{ fill: '#434342' }}
      />
      <polygon
        points='68.24 43.63 68.24 37.44 70.59 37.44 70.59 35.25 63.18 35.25 63.18 37.44 65.53 37.44 65.53 43.63 68.24 43.63'
        style={{ fill: '#434342' }}
      />
      <path
        d='M80.73,40a3.36,3.36,0,0,0-.06-3.25,2.85,2.85,0,0,0-1.27-1.1,4.51,4.51,0,0,0-1.94-.38H73.59v8.38H76.3V41.52H77l1.37,2.11h2.89L79.61,41A2.79,2.79,0,0,0,80.73,40Zm-2.62-.85a1.12,1.12,0,0,1-.82.26h-1v-2h1a1.17,1.17,0,0,1,.82.25,1,1,0,0,1,.28.73A.91.91,0,0,1,78.11,39.13Z'
        style={{ fill: '#434342' }}
      />
      <path
        d='M84.37,35.25v4.61a4,4,0,0,0,1,2.92,4.49,4.49,0,0,0,5.77,0,4,4,0,0,0,1-2.92V35.25H89.54v4.53a2.26,2.26,0,0,1-.32,1.34,1.06,1.06,0,0,1-.91.42,1.1,1.1,0,0,1-.92-.42,2.26,2.26,0,0,1-.31-1.34V35.25Z'
        style={{ fill: '#434342' }}
      />
      <path
        d='M100,37.34a2.18,2.18,0,0,1,1.76,1l1.71-1.6A3.9,3.9,0,0,0,102,35.49a4.82,4.82,0,0,0-2.09-.44,4.71,4.71,0,0,0-2.29.56A4,4,0,0,0,96,37.17a4.73,4.73,0,0,0,0,4.54,4,4,0,0,0,1.61,1.56,4.71,4.71,0,0,0,2.29.56,4.82,4.82,0,0,0,2.09-.44,3.9,3.9,0,0,0,1.52-1.24l-1.71-1.6a2.18,2.18,0,0,1-1.76,1A1.77,1.77,0,0,1,98.66,41a2.55,2.55,0,0,1,0-3.06A1.77,1.77,0,0,1,100,37.34Z'
        style={{ fill: '#434342' }}
      />
      <polygon
        points='105.91 37.44 108.26 37.44 108.26 43.63 110.97 43.63 110.97 37.44 113.32 37.44 113.32 35.25 105.91 35.25 105.91 37.44'
        style={{ fill: '#434342' }}
      />
    </svg>
  );
};
