import React, { Component } from 'react';
import innerHeight from 'ios-inner-height';
import LazyBgPicture from './../../common/picture/lazyBgPicture';

class Slide extends Component {
  state = {};

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { image, heightScale } = this.props;

    const width = window.innerWidth;
    const height = innerHeight() * heightScale;
    const mobile = width < 768;

    const imgWidth = image.size.width;
    const imgHeight = image.size.height;
    const calcWidth = (imgWidth / imgHeight) * height;
    const calcHeight = (imgHeight / imgWidth) * width;

    if (width <= height) {
      // Portrait
      this.setState({ width: `${calcWidth}px`, height: '100%' });
    } else {
      // Landscape
      if (calcWidth < width) this.setState({ width: '100%', height: `${calcHeight}px`, mobile });
      else this.setState({ width: `${calcWidth}px`, height: '100%', mobile });
    }
  };

  render() {
    const { styles, num_slides, bg, image, src, ext, showImage, children } = this.props;
    const { width, height } = this.state;

    return (
      <div className={styles.slide} style={{ width: `${100 / num_slides}%` }}>
        <div className={styles.slide__image} style={{ width: width, height: height }}>
          <LazyBgPicture src={showImage ? src : ''} ext={ext} alt={image.alt} bgImg={bg} />
        </div>
        {children}
      </div>
    );
  }
}

export default Slide;
