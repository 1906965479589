import React from 'react';
import styles from '../../styles/modules/extras/content.module.scss';

const E404Content = React.memo(() => {
  return (
    <section id='terms-content' className={`${styles.content} container__fixed-width`}>
      <h2 className='extra-title'>Deze pagina kan niet worden gevonden.</h2>
      <p className='extra-text'>
        Helaas kunnen we de pagina die u zoekt niet vinden. Dit komt doordat de pagina niet meer bestaat, of doordat een
        typfout is gemaakt bij het invoeren van het adres.
      </p>
      <p className='extra-text'>U kunt het via de navigatie opnieuw proberen.</p>
    </section>
  );
});

export default E404Content;
