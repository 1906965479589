const fs = require('fs');
const resizeImg = require('resize-img');
const webp = require('webp-converter');
const sqip = require('sqip');

// webp.grant_permission();

const projectName = 'vuurmolen';
const noImages = 22;

const createImages = (basePath, thumbs = false, mobile = false) => {
  const sqipPromises = new Array(noImages).fill(0).map((_, index) => {
    const readPath = `${basePath}${thumbs ? 'thumbs/' : ''}${index}`;
    const wirtePath = `${basePath}resized/${thumbs ? 'thumbs/' : ''}${index}${mobile ? '_mobile' : ''}`;

    return new Promise((resolve, reject) => {
      // Resize images for desktop
      resizeImg(fs.readFileSync(`${readPath}.jpg`), { width: 1920 })
        .then((image) => {
          // Write resized desktop image
          fs.writeFileSync(`${wirtePath}.jpg`, image);

          // Convert image to webp
          webp
            .cwebp(`${wirtePath}.jpg`, `${wirtePath}.webp`, '-q 90', '-v')
            .then(() => {
              // WEBP Convert complete

              // Resize image to double the size
              resizeImg(fs.readFileSync(`${readPath}.jpg`), { width: 3840 })
                .then((image) => {
                  // Write resized image
                  fs.writeFileSync(`${wirtePath}@2x.jpg`, image);
                  // Convert image to webp
                  webp
                    .cwebp(`${wirtePath}@2x.jpg`, `${wirtePath}@2x.webp`, '-q 90', '-v')
                    .then((response) => {
                      // Create SQIP of image
                      resolve({
                        image: index,
                        resized: true,
                        sqip: mobile
                          ? false
                          : sqip({
                              filename: `${readPath}.jpg`,
                              numberOfPrimitives: 6,
                              mode: 0,
                              blur: 12,
                            }).svg_base64encoded,
                      });
                    })
                    .catch((e) => reject('WEBP error' + e));
                })
                .catch((e) => reject('Resize error' + e));
            })
            .catch((e) => reject('WEBP error' + e));
        })
        .catch((e) => reject('Resize error' + e));
    });
  });

  return sqipPromises;
};

const createCardImage = (basePath) => {
  const readPath = `${basePath}card`;
  const wirtePath = `${basePath}resized/card`;

  return [
    new Promise((resolve, reject) => {
      // Resize images for desktop
      resizeImg(fs.readFileSync(`${readPath}.jpg`), { width: 1920 })
        .then((image) => {
          // Write resized desktop image
          fs.writeFileSync(`${wirtePath}.jpg`, image);

          // Convert image to webp
          webp
            .cwebp(`${wirtePath}.jpg`, `${wirtePath}.webp`, '-q 90', '-v')
            .then(() => {
              // WEBP Convert complete

              // Resize image to double the size
              resizeImg(fs.readFileSync(`${readPath}.jpg`), { width: 3840 })
                .then((image) => {
                  // Write resized image
                  fs.writeFileSync(`${wirtePath}@2x.jpg`, image);
                  // Convert image to webp
                  webp
                    .cwebp(`${wirtePath}@2x.jpg`, `${wirtePath}@2x.webp`, '-q 90', '-v')
                    .then((response) => {
                      // Create SQIP of image
                      resolve({
                        image: 'card',
                        resized: true,
                        sqip: sqip({
                          filename: `${readPath}.jpg`,
                          numberOfPrimitives: 6,
                          mode: 0,
                          blur: 12,
                        }).svg_base64encoded,
                      });
                    })
                    .catch((e) => reject('WEBP error' + e));
                })
                .catch((e) => reject('Resize error' + e));
            })
            .catch((e) => reject('WEBP error' + e));
        })
        .catch((e) => reject('Resize error' + e));
    }),
  ];
};

(async () => {
  const projectPath = `${projectName}/`;

  // Standard images
  Promise.all(createImages(projectPath, false, false))
    .then((values) => {
      console.log('Created standard images');

      fs.writeFileSync(
        `${projectPath}resized/style.txt`,
        values
          .map((value) => {
            return `.${projectName}_${value.image} {background-image: url(data:image/jpeg;base64,${value.sqip});}`;
          })
          .join('\n'),
        (error) => {
          if (error) console.log(error);
        }
      );
    })
    .catch((error) => console.log(error));

  // Mobile images
  Promise.all(createImages(projectPath, false, true))
    .then((values) => {
      console.log('Created mobile images');
    })
    .catch((error) => console.log(error));

  // Thumbnails
  Promise.all(createImages(projectPath, true, false))
    .then((values) => {
      console.log('Created thumbnails');

      fs.writeFileSync(
        `${projectPath}resized/thumbstyle.txt`,
        values
          .map((value) => {
            return `.${projectName}_thumb_${value.image} {background-image: url(data:image/jpeg;base64,${value.sqip});}`;
          })
          .join('\n'),
        (error) => {
          if (error) console.log(error);
        }
      );
    })
    .catch((error) => console.log(error));

  // Card image
  Promise.all(createCardImage(projectPath))
    .then((values) => {
      console.log('Created card image');

      fs.writeFileSync(
        `${projectPath}resized/cardstyle.txt`,
        values
          .map((value) => {
            return `.${projectName}_${value.image} {background-image: url(data:image/jpeg;base64,${value.sqip});}`;
          })
          .join('\n'),
        (error) => {
          if (error) console.log(error);
        }
      );
    })
    .catch((error) => console.log(error));
})();
