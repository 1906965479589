import React from 'react';
import { Bed, House, Note, Floor, Bath } from '../../components/images/icons';

const apps = [
  {
    project: 'residentie-schalkhoven',
    alias: 'app_9',
    name: 'Appartement 9',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 9</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '220.420',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>1 slaapkamer</p> },
      { icon: <House />, text: <p className='main-text'>99 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 2</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig gelijkvloers twee slaapkamer appartement met privé tuintje en terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '2' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '99 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '29 m²' },
          { description: 'Slaapkamers', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oppervlakte terras', value: '28 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Conciiërge', value: 'Ja' },
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 220.420' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_1',
    name: 'Appartement 1',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 1</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '240.850',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>105 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 0</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig gelijkvloers appartement met twee slaapkamers, privé-tuin, terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '0' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '105 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '36 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '10 m²' },
          { description: 'Opp. slaapkamer 2', value: '10 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '10 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 240.850' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_5',
    name: 'Appartement 5',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 5</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '240.850',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>105 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 1</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig appartement met twee slaapkamers, terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '1' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '105 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '36 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '10 m²' },
          { description: 'Opp. slaapkamer 2', value: '10 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '10 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 240.850' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_11',
    name: 'Appartement 11',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 11</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '252.300',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>114 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 2</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig appartement met twee slaapkamers, twee grote terrassen, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '2' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '114 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '30 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '7 m²' },
          { description: 'Opp. slaapkamer 2', value: '12 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '33 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 252.300' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_7',
    name: 'Appartement 7',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 7</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '270.000',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>118 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 1</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig appartement met twee slaapkamers, terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '1' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '118 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '47 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '8 m²' },
          { description: 'Opp. slaapkamer 2', value: '12 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '13 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 270.000' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_8',
    name: 'Appartement 8',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 8</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '292.110',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>127 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 1</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig appartement met twee slaapkamers, terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '1' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '127 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '45 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '11 m²' },
          { description: 'Opp. slaapkamer 2', value: '12 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '10 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 292.110' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_6',
    name: 'Appartement 6',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 6</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '297.350',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>133 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 1</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig appartement met twee slaapkamers, groot terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '1' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '133 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '47 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '10 m²' },
          { description: 'Opp. slaapkamer 2', value: '9 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '33 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 297.350' }] },
    ],
  },
  {
    project: 'residentie-schalkhoven',
    alias: 'app_3',
    name: 'Appartement 3',
    title: (
      <React.Fragment>
        <p className='slide-title'>Schalkhoven</p>
        <p className='slide-title slide-title__lightblue'>Appartement 3</p>
      </React.Fragment>
    ),
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat',
    },
    price: '314.345',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>139 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>verdieping 0</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig gelijkvloers appartement met twee slaapkamers, privé-tuin, groot terras, vloerverwarming, zonnepanelen, alle comfort en keuze naar afwerking. Ruime kelder en berging inbegrepen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Pand naam', value: 'Residentie Schalkhoven', rows: 2 },
          { description: 'Verdieping', value: '1' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Staanplaatsen binnen', value: '14' },
          { description: 'Parkeerplaatsen buiten', value: '5' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '139 m²', rows: 2 },
          { description: 'Oppervlakte woonkamer', value: '49 m²' },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '8 m²' },
          { description: 'Opp. slaapkamer 2', value: '12 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '1' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '10 m²' },
          { description: 'Gepantserde deur', value: 'Ja' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte tuin', value: '760 m²' },
          { description: 'Oriëntatie tuin', value: 'Zuidoost' },
          { description: 'Oppervlakte terras', value: '22 m²' },
          { description: 'Oriëntatie terras', value: 'Zuidoost' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Lift', value: 'Ja' },
          { description: 'Aangepast aan handicap', value: 'Ja', rows: 2 },
          { description: 'Gepantserde deur', value: 'Ja' },
          { description: 'Visio-telefoon', value: 'Ja' },
        ],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '71 kWh/m²' },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
        ],
      },
      { title: 'Financieel', items: [{ description: 'Prijs', value: '&euro; 314.345' }] },
    ],
  },
];

export function getApps() {
  return apps;
}
