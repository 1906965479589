import React, { Component } from 'react';
import Slide from '../top/header/slide';
import NameBox from '../top/header/namebox';
import Navigation from '../common/navigation';
import { getHighlights } from './../../services/data/highlights';

import styles from '../../styles/modules/extras/header.module.scss';

class Header extends Component {
  state = {
    mobile: window.innerWidth < 768,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const prevState = this.state;

    if (prevState.mobile !== nextState.mobile) return true;
    return false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.scrollTo(0, 0);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (this.state.mobile !== window.innerWidth < 768) this.setState({ mobile: window.innerWidth < 768 });
  };

  render() {
    const { mobile } = this.state;

    const image = { alt: 'Overdragen sleutel', size: { width: 1920, height: 697 } };

    return (
      <header id='home' className={styles.extras_header}>
        <div className={styles.slider}>
          <div className={styles.slider_container} style={{ width: '100%' }}>
            <Slide
              styles={styles}
              num_slides='1'
              image={image}
              bg='about'
              src={`about${mobile ? '_mobile_long' : ''}`}
              ext='jpg'
              heightScale='0.5'
              showImage={true}
            />
          </div>
        </div>
        <Navigation className={`${styles.navigation} ${styles.navigation__top}`} />
      </header>
    );
  }
}

export default Header;
